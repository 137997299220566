.text-justify{
    text-align: justify !important;
}
.container{
    max-width: 1200px !important;
}
header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 0px;
    z-index: 3;
}
.banner-area-two {
    position: relative;
    padding: 235px 0 0;
    z-index: 1;
}
.banner-bg-two, .banner-bg-two:before {
    background-size: cover;
    background-position: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
}
.banner-bg-two {
    background-image: url(./images/banner_bg02.jpg);
    top: 0;
    height: calc(100% - 164px);
}

.banner-bg-two:before {
    content: "";
    bottom: -40px;
    background-image: url(./images/banner_shape.svg);
    height: 722px;
}
.banner-bg-two, .banner-bg-two:before {
    background-size: cover;
    background-position: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
}
.banner-content .title {
    font-size: 55px;
    margin-bottom: 45px;
    line-height: 1.4;
    letter-spacing: -.01em;
}
.custom-container-four {
    max-width: 1200px !important;
}

.navbar {
    --bs-navbar-nav-link-padding-x: 1rem !important;
    --bs-navbar-toggler-padding-x: 1rem !important;
    --bs-navbar-toggler-icon-bg : url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg fill='%23fff' data-name='Livello 1' id='Livello_1' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cpath d='M64 0a64 64 0 1 0 64 64A64.07 64.07 0 0 0 64 0Zm0 122a58 58 0 1 1 58-58A58.07 58.07 0 0 1 64 122Z'/%3E%3Cpath d='M90 61H38a3 3 0 0 0 0 6H90a3 3 0 0 0 0-6Z'/%3E%3Cpath d='M90 74H38a3 3 0 0 0 0 6H90a3 3 0 0 0 0-6Z'/%3E%3Cpath d='M90 48H38a3 3 0 0 0 0 6H90a3 3 0 0 0 0-6Z'/%3E%3C/svg%3E") !important;
}
.box{
    background: #fff;
    box-shadow: 0 34px 35px rgba(160,171,191,.21);
    border-radius: 20px;
    position: relative;
    color: #333;
}
.choose-bg {
    background-image: url(./images/choose_bg.jpg);
    background-size: cover;
    background-position: 50%;
    position: relative;
}
ul li{
    margin-bottom: 10px;
}
.choose-bg:after, .choose-bg:before {
    content: "";
    position: absolute;
    left: 0;
    background-position: 50%;
    background-repeat: repeat;
    width: 100%;
}
.choose-bg:before {
    top: 0;
    background-image: url(./images/choose_shape01.png);
    height: 25px;
}
.choose-bg:after {
    bottom: 0;
    background-image: url(./images/choose_shape02.png);
    height: 19px;
}
@media (min-width: 1200px){
    .navbar-expand-xl .navbar-nav .nav-link {
        color: #ffffff !important;
    }
}


@media (max-width: 1500px){
    .banner-bg-two:before {
        height: 600px;
    }
    .banner-area-two {
        padding: 180px 0 0;
    }
    .banner-bg-two {
        height: calc(100% - 115px);
    }
    .banner-area-two .banner-content img {
        max-width: 700px;
    }
}
.form-control {
    direction: rtl;
    font-size: 16px!important;
    padding: 12px 18px!important;
    text-align: right!important;
}
.modal .btn{
    min-width: 300px !important;
}
.bg-color1{
    background-color: #40785b !important;
    border-color: #40785b !important;
}
.close {
    position: absolute;
    right: 10px;
    top: -20px;
    color: #fff;
    background-color: #40785b !important;
    padding: 6px !important;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

@media (max-width: 1199px){
    header{
        padding: 10px 0px;
    }
    .container {
        max-width: 960px !important;
    }
    .banner-area-two .banner-content img {
        max-width: 600px;
    }
    .banner-area-two {
        padding: 170px 0 0;
    }
    .banner-bg-two {
        height: calc(100% - 100px);
    }
    .banner-bg-two:before {
        height: 480px;
    }
    .banner-area-two .banner-content .title {
        margin-bottom: 50px;
    }
}

@media (max-width: 991px){
    header {
        padding: 15px 0;
    }
    .banner-area-two {
        padding: 150px 0 0;
    }
    .banner-bg-two {
        height: calc(100% - 120px);
    }
    .banner-bg-two:before {
        height: 400px;
    }
    .banner-area-two .banner-content img {
        max-width: 600px;
    }
    .banner-area-two .banner-content .title {
        font-size: 55px;
        line-height: 1.2;
    }
}
@media (max-width: 768px){
    .banner-area-two .banner-content .title {
        font-size: 32px;
    }
    .banner-area-two {
        padding: 130px 0 0;
    }
    .banner-bg-two {
        height: calc(100% - 95px);
    }
    .banner-bg-two:before {
        height: 260px;
        background-position: bottom;
    }
    .banner-area-two .banner-content img {
        max-width: 100%;
    }
}
